<template>
  <div>
    <b-row class="match-height">
      <b-col md="4">
        <b-card>
          <b-card-title>跑腿配置录入</b-card-title>
          <b-card-sub-title>请先选择渠道再录入</b-card-sub-title>
          <div style="height: 1rem"></div>
          <ErrandForm @handleErrandForm="handleErrandForm"></ErrandForm>
        </b-card>
      </b-col>
      <b-col md="8">
        <b-card>
          <b-card-title>跑腿官网</b-card-title>
          <b-card-sub-title class="mb-1">快捷导航</b-card-sub-title>
          <div class="err-btn">
            <b-button
              style="margin-right: 1rem"
              v-for="(item, index) in errList"
              :key="item.title"
              :variant="item.variant"
              @click="chooseErrand(item, index)"
            >
              {{ item.title }}
            </b-button>
          </div>
        </b-card>
        <b-row>
          <b-card
            v-for="item in errandManagementItem"
            :key="item.pid"
            class="store-card"
            style="margin-left: 1rem"
          >
            <StoreDataCard :storeItem="item"></StoreDataCard>

            <!-- <b-card-title>已绑定配置</b-card-title>
            <b-card-sub-title>最新绑定的跑腿配置</b-card-sub-title>
            <div style="height: 1rem"></div> -->
            <!-- <MyTable
              :fields="errandManagementFields"
              :items="errandManagementItem"
              :isShowHeader="false"
            ></MyTable> -->
          </b-card>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BButton,
} from "bootstrap-vue";
import ErrandForm from "./childComps/ErrandForm.vue";
import StoreDataCard from "./childComps/StoreDataCard.vue";

import MyTable from "@/components/MyTable.vue";
import { getUserInfo } from "@/utils/api";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BButton,
    ErrandForm,
    MyTable,
    StoreDataCard,
  },
  data() {
    return {
      errandManagementFormTitle: "",
      errList: [
        {
          variant: "outline-primary",
          title: "达达",
          value: "dada",
        },
        {
          variant: "outline-primary",
          title: "闪送",
          value: "shansong",
        },
        {
          variant: "outline-primary",
          title: "UU",
          value: "uu",
        },
        {
          variant: "outline-primary",
          title: "顺丰同城",
          value: "shunfeng",
        },
      ],
      // errandManagementFields: [
      //   {
      //     key: "pid",
      //     label: "门店ID",
      //   },
      //   {
      //     key: "storeId",
      //     label: "门店ID",
      //   },
      //   {
      //     key: "channel",
      //     label: "跑腿",
      //   },
      //   {
      //     key: "app_id",
      //     label: "应用ID",
      //   },
      //   {
      //     key: "app_secret",
      //     label: "应用密钥",
      //   },
      //   {
      //     key: "merchant_id",
      //     label: "商户编号",
      //   },
      //   {
      //     key: "shop_no",
      //     label: "门店编号",
      //   },
      //   {
      //     key: "city_code",
      //     label: "城市代码",
      //   },
      //   {
      //     key: "city_name",
      //     label: "城市名称",
      //   },
      //   {
      //     key: "action",
      //     label: "操作",
      //   },
      // ],
      errandManagementItem: [],
    };
  },
  created() {
    this.getErrandInfo();
  },
  methods: {
    //获取跑腿数据
    getErrandInfo() {
      getUserInfo("sysAdminWeb.deliveryList", {}).then((res) => {
        if (res && res.data && res.data.code === "200") {
          console.log(res, "跑腿数据");
          this.errandManagementItem = res.data.data;
        }
      });
    },
    //添加跑腿接口
    handleErrandForm(params) {
      console.log(params, "传递到表单数据");
      getUserInfo("sysAdminWeb.deliveryAdd", params).then((res) => {
        if (res) {
          console.log(res, "提交over");
          if (res.data.code === "400") {
            this.changeTips(res.data.msg, "warning");
          } else if (res.data.code === "200") {
            this.getErrandInfo();
            this.changeTips(res.data.msg, "success");
          } else {
            this.changeTips("提交失败", "error");
          }
        }
      });
    },
    //选择跑腿地址
    chooseErrand(item) {
      console.log(item, "选择到数据");
      if (item.value === "dada") {
        window.open("http://newopen.imdada.cn");
      } else if (item.value === "shansong") {
        window.open("http://seller.ishansong.com/user/home");
      } else if (item.value === "uu") {
        window.open("http://open.uupt.com");
      } else if (item.value === "shunfeng") {
        window.open(
          "https://commit-openic.sf-express.com/open/api/docs/index#/homepage"
        );
      } else {
        return;
      }
    },
    //tips
    changeTips(val, color) {
      this.$bvToast.toast(`${val}`, {
        title: `提示`,
        variant: color,
        solid: true,
      });
    },
  },
};
</script>

<style lang="scss">
.err-btn {
  display: flex;
}
</style>
