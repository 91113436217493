<template>
  <div>
    <div class="top-btn">
      <b-button
        style="margin-right: 1rem"
        v-for="(item, index) in btnList"
        :key="item.title"
        :variant="item.variant"
        @click="handleTopBtn(item, index)"
      >
        {{ item.title }}
      </b-button>
    </div>

    <b-form @submit.prevent>
      <b-row>
        <b-col cols="12">
          <b-form-group label="门店ID" label-for="h-storeId" label-cols-md="4">
            <b-form-input
              v-model.trim="errandFormParams.storeId"
              id="h-storeId"
              type="number"
              placeholder="请输入门店ID"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="跑腿" label-for="h-value" label-cols-md="4">
            <v-select
              v-model.trim="errandFormParams.channel"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="option"
              :reduce="(option) => option.value"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="应用ID" label-for="h-app_id" label-cols-md="4">
            <b-form-input
              v-model.trim="errandFormParams.app_id"
              id="h-app_id"
              placeholder="请输入应用ID"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="应用密钥"
            label-for="h-app_secret"
            label-cols-md="4"
          >
            <b-form-input
              v-model.trim="errandFormParams.app_secret"
              id="h-app_secret"
              placeholder="请输入应用密钥"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="商户编号"
            label-for="h-merchant_id"
            label-cols-md="4"
          >
            <b-form-input
              v-model.trim="errandFormParams.merchant_id"
              id="h-merchant_id"
              placeholder="请输入商户编号"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="门店编号"
            label-for="h-shop_no"
            label-cols-md="4"
          >
            <b-form-input
              v-model.trim="errandFormParams.shop_no"
              id="h-shop_no"
              placeholder="请输入门店编号"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="城市代码"
            label-for="h-city_code"
            label-cols-md="4"
          >
            <b-form-input
              v-model.trim="errandFormParams.city_code"
              id="h-city_code"
              placeholder="请输入城市代码"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="城市名称"
            label-for="h-city_name"
            label-cols-md="4"
          >
            <b-form-input
              v-model.trim="errandFormParams.city_name"
              id="h-city_name"
              placeholder="请输入城市名称"
            />
          </b-form-group>
        </b-col>

        <!-- submit and reset -->
        <b-col offset-md="4">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click="submitForm"
          >
            提交
          </b-button>
          <!-- <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
          >
            重置
          </b-button> -->
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BTable,
  BCard,
  BCardTitle,
  BCardText,
  BCardBody,
  BButton,
  BRow,
  BCol,
  BTab,
  BTabs,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
} from "bootstrap-vue";
//点击按钮时的波纹效果
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";

export default {
  components: {
    BTable,
    BCard,
    BCardTitle,
    BCardText,
    BCardBody,
    BButton,
    BRow,
    BCol,
    BTab,
    BTabs,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      option: [
        { title: "达达", value: "dada" },
        { title: "闪送", value: "shansong" },
        { title: "UU", value: "uu" },
        { title: "顺丰同城", value: "shunfeng" },
      ],
      btnList: [
        {
          variant: "outline-secondary",
          title: "达达",
          value: "dada",
        },
        {
          variant: "outline-secondary",
          title: "闪送",
          value: "shansong",
        },
        {
          variant: "outline-secondary",
          title: "UU",
          value: "uu",
        },
        {
          variant: "outline-secondary",
          title: "顺丰同城",
          value: "shunfeng",
        },
      ],
      currentIndex: -1,
      errandFormParams: {
        storeId: "",
        channel: "",
        app_id: "",
        app_secret: "",
        merchant_id: "",
        shop_no: "",
        city_code: "",
        city_name: "",
      },
      dadaParams: {
        //达达基础配置
        storeId: "",
        channel: "dada",
        app_id: "",
        app_secret: "-",
        merchant_id: "00000",
        shop_no: "00000-0000000",
        city_code: "",
        city_name: "",
      },
      shansongParams: {
        //闪送基础配置
        storeId: "",
        channel: "shansong",
        app_id: "-----------------",
        app_secret: "-----------------",
        merchant_id: "------------",
        shop_no: "------------",
        city_code: "",
        city_name: "",
      },
      uuParams: {
        //uu基础配置
        storeId: "",
        channel: "uu",
        app_id: "",
        app_secret: "",
        merchant_id: "------------------------------",
        shop_no: "",
        city_code: "",
        city_name: "",
      },
      shunfengParams: {
        //顺丰基础配置
        storeId: "",
        channel: "shunfeng",
        app_id: "",
        app_secret: "",
        merchant_id: "------------",
        shop_no: "------------",
        city_code: "",
        city_name: "----",
      },
    };
  },
  methods: {
    handleTopBtn(item, index) {
      this.currentIndex = index;
      if (this.currentIndex === index) {
        this.btnList.forEach((it) => {
          it.variant = "outline-secondary";
        });
        item.variant = "primary";
      }

      if (item.value === "dada") {
        this.errandFormParams = this.dadaParams;
      } else if (item.value === "shansong") {
        this.errandFormParams = this.shansongParams;
      } else if (item.value === "uu") {
        this.errandFormParams = this.uuParams;
      } else {
        this.errandFormParams = this.shunfengParams;
      }

      console.log(item, "choose");
    },
    submitForm() {
      this.$emit('handleErrandForm',this.errandFormParams)
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.top-btn {
  display: flex;
  margin-bottom: 1rem;
}
</style>
