<template>
  <!-- Col: Payment Details -->
  <b-col xl="12" cols="12">
    <div>
      <table>
        <tbody>
          <tr>
            <td class="pr-1 td-1">ID:</td>
            <td class="td-2">{{ storeItem.pid }}</td>
          </tr>
          <tr>
            <td class="pr-1 td-1">门店ID:</td>
            <td class="td-2">{{ storeItem.storeId }}</td>
          </tr>
          <tr>
            <td class="pr-1 td-1">跑腿:</td>
            <td class="td-2">{{ storeItem.channel }}</td>
          </tr>
          <tr>
            <td class="pr-1 td-1">应用ID:</td>
            <td class="td-2">{{ storeItem.app_id }}</td>
          </tr>
          <tr>
            <td class="pr-1 td-1">应用密钥:</td>
            <td class="td-2">{{ storeItem.app_secret }}</td>
          </tr>
          <tr>
            <td class="pr-1 td-1">商户编号:</td>
            <td class="td-2">{{ storeItem.merchant_id }}</td>
          </tr>
          <tr>
            <td class="pr-1 td-1">门店编号:</td>
            <td class="td-2">{{ storeItem.shop_no }}</td>
          </tr>
          <tr>
            <td class="pr-1 td-1">城市代码:</td>
            <td class="td-2">{{ storeItem.city_code }}</td>
          </tr>
          <tr>
            <td class="pr-1 td-1">城市名称:</td>
            <td class="td-2">{{ storeItem.city_name }}</td>
          </tr>
          <tr class="tr-btn"></tr>
        </tbody>
      </table>
      <div style="text-align:right;margin-top:1rem">
        <b-button
          class="btn"
          v-if="
            storeItem.channel === 'shansong' || storeItem.channel === 'shunfeng'
          "
          variant="outline-primary"
          size="sm"
          pill
          @click="handleAuth(storeItem)"
        >
          去授权
        </b-button>
      </div>
    </div>
  </b-col>
</template>

<script>
import { BCard, BCardText, BRow, BCol, BButton } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BButton,
  },
  props: {
    storeItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    handleAuth(storeItem) {
      if (storeItem.channel === "shansong") {
        window.open(
          "https://mp.iguaka.com/gdelivery/auth/shansong/" + storeItem.storeId
        );
      } else if (storeItem.channel === "shunfeng") {
        window.open(
          "https://mp.iguaka.com/gdelivery/auth/shunfeng/" + storeItem.storeId
        );
      } else {
        return;
      }
    },
  },
};
</script>

<style lang="scss">
.tr-btn {
  // width: 100%;
  // display: flex;
  // justify-content: flex-end;
  position: relative;
  right: 0;
}
.td-1 {
  color: #999;
}
.td-2 {
  text-align: right;
}
</style>
